import React from "react";
import { Box, Card, CardContent, Typography, CardActions, Button } from "@mui/material";
import { Notifications as NotificationsIcon } from "@mui/icons-material";
import { enqueueSnackbar } from 'notistack';

import { AppContext } from "../../context/App";

class BellContent extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            ringingSX: this.setRingingSXWithSize({ ringingSX: { transform: "translate(0, -100%) rotate(0deg)", transition: "transform " + this.animation.ringingTimeoutDuration + "ms ease-in-out" } }, true),
        }

        this.setBellSize();

    }

    animation = {
        lastDirection: 0,
        ringingTimeout: null,
        ringingAngle: 0,
        ringingTimeoutDuration: 1000,
        lastBellState: 0,
        animateInterval: null,
        maxRingingAngle: 40,
    }

    bellStates = {}

    setBellSize() {
        let bellSize = this.props.bellSize;

        switch (+bellSize) {
            case 1:
                this.animation.ringingTimeoutDuration = 800;
                break;
            case 2:
                this.animation.ringingTimeoutDuration = 1000;
                break;
            case 3:
                this.animation.ringingTimeoutDuration = 1200;
                break;
            default:
                this.animation.ringingTimeoutDuration = 1000;
                break;
        }
    }

    setRingingSXWithSize(ringingSX, shouldReturn = false) {
        ringingSX = { ...ringingSX.ringingSX };
        switch (+this.props.bellSize) {
            case 1:
                ringingSX.fontSize = 70;
                break;
            case 2:
                ringingSX.fontSize = 100;
                break;
            case 3:
                ringingSX.fontSize = 130;
                break;
            default:
                ringingSX.fontSize = 100;
                break;
        }

        if (shouldReturn) {
            return ringingSX;
        } else {
            this.setState({ ringingSX: ringingSX });
        }
    }




    animateBellRingingLoop() {
        let bellState = this.props.bellState;
        if ((bellState === this.context.bellState.STOPPED || bellState === this.context.bellState.BLOCKED) || (bellState === this.context.bellState.STOPPING && this.animation.ringingAngle === 0)) {
            this.animation.ringingAngle = 0;
            this.animation.ringingTimeout = null;
            clearTimeout(this.animation.ringingTimeout);
            clearInterval(this.animation.animateInterval);
            this.setRingingSXWithSize({ ringingSX: { transform: "translate(0, -100%) rotate(0deg)", transition: "transform " + this.animation.ringingTimeoutDuration + "ms ease-in-out" } });
            return;
        }

        let angle = this.animation.lastDirection ? this.animation.ringingAngle : this.animation.ringingAngle * -1;
        this.setRingingSXWithSize({ ringingSX: { transform: "translate(0, -100%) rotate(" + angle + "deg)", transition: "transform " + this.animation.ringingTimeoutDuration + "ms ease-in-out" } });
        this.animation.lastDirection = !this.animation.lastDirection;
        this.animation.ringingTimeout = setTimeout(() => {
            this.animateBellRingingLoop(bellState);
        }, this.animation.ringingTimeoutDuration);
    }

    animateBellRinging(bellState) {

        if (bellState === this.animation.lastBellState || bellState === this.context.bellState.RINGING) {
            return;
        }
        this.animation.lastBellState = bellState;

        if (this.animation.ringingTimeout === null && bellState !== this.context.bellState.STOPPED && bellState !== this.context.bellState.BLOCKED) {
            this.animation.ringingTimeout = setTimeout(() => {
                this.animateBellRingingLoop();
            }, this.animation.ringingTimeoutDuration);

        } else if (((bellState === this.context.bellState.STOPPED || bellState === this.context.bellState.BLOCKED) && this.animation.ringingTimeout !== null) || (this.animation.ringingAngle === this.context.bellState.STOPPED && bellState === this.context.bellState.STOPPING)) {
            clearTimeout(this.animation.ringingTimeout);
            this.animation.ringingTimeout = null;
        }

        if (this.animation.animateInterval === null) {

            if (bellState === this.context.bellState.STARTING) {
                this.animation.animateInterval = setInterval(() => {
                    if (this.animation.ringingAngle < this.animation.maxRingingAngle) {
                        this.animation.ringingAngle = this.animation.ringingAngle + 1;
                    } else {
                        clearInterval(this.animation.animateInterval);
                        this.animation.animateInterval = null;
                    }
                }, 200);

            } else if (bellState === this.context.bellState.STOPPING) {
                this.animation.animateInterval = setInterval(() => {
                    if (this.animation.ringingAngle > 0) {
                        this.animation.ringingAngle = this.animation.ringingAngle - 1;
                    } else {
                        clearInterval(this.animation.animateInterval);
                        this.animation.animateInterval = null;
                    }
                }, 400);
            }
        }
        if (bellState === this.context.bellState.STOPPED || bellState === this.context.bellState.BLOCKED) {
            clearInterval(this.animation.animateInterval);
            this.animation.animateInterval = null;
            this.setRingingSXWithSize({ ringingSX: { transform: "translate(0, -100%) rotate(0deg)", transition: "transform " + this.animation.ringingTimeoutDuration + "ms ease-in-out" } });
        }
    }

    render() {
        this.animateBellRinging(this.props.bellState);

        let classes = "bell-icon";
        let text;

        switch (this.props.bellState) {
            case "-1":
                classes += " bell-icon--blocked";
                text = "Blokovaný";
                break;
            case "0":
                classes += " bell-icon--stopped";
                text = "Zastavený";
                break;
            case "1":
                classes += " bell-icon--stopping";
                text = "Zastavuje";
                break;
            case "2":
                classes += " bell-icon--starting";
                text = "Štartuje";
                break;
            case "3":
                classes += " bell-icon--ringing";
                text = "Zvoní";
                break;
            default:
                text = "Neznámý stav";
        }

        return (
            <Box className={classes} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} >
                <div className="bell-icon__wrapper">
                    <NotificationsIcon
                        className={classes} sx={this.state.ringingSX}
                    />
                </div>
                <Typography gutterBottom variant="body2" component="div" align="center" sx={{ mt: 2 }}>
                    {text}
                </Typography>
            </Box >
        );
    }
}

class Bell extends React.Component {
    static contextType = AppContext;


    handleBellUnlock = () => {
        const { socket } = this.context;

        let belfryID = this.props.belfryID;
        let bellNumber = this.props.number;
        socket.emit('belfryUnlockBell', { belfryID, bellNumber }, (response) => {
            if (response.success) {
                enqueueSnackbar("Príkaz na odblokovanie zvonu odoslaný", { variant: "info" });
            } else {
                enqueueSnackbar("Chyba pri odosielaní príkazu na odblokovanie zvonu", { variant: "error" });
            }
        });
    }

    handleBellStop = () => {
        const { socket } = this.context;

        let belfryID = this.props.belfryID;
        let bellNumber = this.props.number;

        let bells = [];
        bells.push(bellNumber);

        this.context.socket.emit("belfryRingBell",
            {
                belfryID: belfryID,
                bells: bells,
                time: 0,
                action: false
            },
            (response) => {
                if (response.success) {
                    enqueueSnackbar("Príkaz na zastavenie zvonu odoslaný", { variant: "info" });
                } else {
                    enqueueSnackbar("Chyba pri odosielaní príkazu na zastavenie zvonu", { variant: "error" });
                }
            });
    }

    render() {
        const { user, bellState } = this.context;

        return (
            <Card sx={{ minWidth: 250, position: 'relative', mb: { xs: 2, md: 0 }, flex: 1, m: '10px' }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" align="center">
                        {this.props.name}
                    </Typography>
                </CardContent>
                {user.role === 'administrator' &&
                    <CardActions sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        position: "absolute",
                        top: "45px",
                        left: "50%",
                        transform: "translate(-50%)"
                    }}>
                        {this.props.state === bellState.BLOCKED &&
                            <Button size="small" color="success" variant="outlined" onClick={this.handleBellUnlock}>Odblokovať</Button>
                        }

                        {this.props.state !== bellState.STOPPED && this.props.state !== bellState.BLOCKED && this.props.state !== bellState.STOPPING && this.props.state !== bellState.UNKNOWN &&
                            <Button size="small" color="error" variant="contained" onClick={this.handleBellStop}>Zastaviť</Button>
                        }
                    </CardActions>
                }
                <CardContent>
                    <BellContent
                        bellState={this.props.state}
                        bellSize={this.props.size}
                    />
                </CardContent>
            </Card>
        );
    }
}

export default Bell;
