import react from 'react';
import { Typography, List, ListItem, ListItemText, Card, CardContent } from '@mui/material';
import { AppContext } from '../../context/App';

class ClosestRingings extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            closestRingings: [],
            lastDownloadedSchedules: {
                last_update: null,
                downloaded: null
            }
        };
    }

    componentDidMount() {
        this.getClosestRingings();
        this.getLastDownloadedSchedules();

        const { socket } = this.context;
        socket.on('belfry_program_status', (data) => {
            console.log(data);
            if (data.belfryID === this.props.belfry.id) {
                delete data.belfryID;
                this.setState({
                    lastDownloadedSchedules: {
                        ...this.state.lastDownloadedSchedules,
                        ...data
                    }
                });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.belfry.id !== this.props.belfry.id) {
            this.getClosestRingings();
            this.getLastDownloadedSchedules();
        }
    }

    getLastDownloadedSchedules() {
        const { socket } = this.context;
        socket.emit('getLastSchedulesDownload', { belfryID: this.props.belfry.id }, (data) => {
            this.setState({ lastDownloadedSchedules: data });
        });
    }

    getClosestRingings() {
        const { socket } = this.context;
        socket.emit("getClosestSchedulesForBellfry", { belfryID: this.props.belfry.id }, (data) => {
            this.setState({ closestRingings: data });
        });
    }

    render() {
        const { closestRingings } = this.state;
        const ringingsMaxCount = 4;


        return (
            <Card sx={{ minWidth: 250, position: 'relative', mb: { xs: 2, md: 0 }, flex: 1, m: '10px' }}>
                <CardContent sx={{ pb: 0 }}>
                    <Typography gutterBottom variant="h5" component="div" align="center">
                        Najbližšie zvonenia
                    </Typography>
                    {this.state.lastDownloadedSchedules.last_update &&
                        <Typography variant="body2" color="text.secondary" align="center">
                            Naposledy aktualizované: {
                                new Date(this.state.lastDownloadedSchedules.last_update).toLocaleString()
                            }
                        </Typography>
                    }
                    {this.state.lastDownloadedSchedules.downloaded &&
                        <Typography variant="body2" color="text.secondary" align="center">
                            Naposledy stiahnuté: {
                                new Date(this.state.lastDownloadedSchedules.downloaded).toLocaleString()
                            }
                        </Typography>
                    }
                </CardContent>
                <CardContent sx={{ pt: 0 }}>

                    <List>
                        {closestRingings.length > 0 && closestRingings.slice(0, ringingsMaxCount).map((ringing, index) => {
                            return (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={ringing.name}
                                        secondary={ringing.start_time}
                                    />
                                </ListItem>
                            );
                        })}
                        {closestRingings.length === 0 &&
                            <ListItem>
                                <ListItemText
                                    primary="Žiadne zvonenia"
                                />
                            </ListItem>
                        }
                    </List>
                </CardContent>
            </Card>
        );
    }
}

export default ClosestRingings;
