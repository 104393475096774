import react from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { enqueueSnackbar } from 'notistack';

import { AppContext } from '../../context/App';
import { Card, Box } from "@mui/material";
import ScheduleDialogForm from './ScheduleDialogForm';
import ScheduleDialog from './ScheduleDialog';

class Calendar extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            scheduleDialogFormOpen: false,
            scheduleDialogFormTitle: "Nové zvonenie",
            scheduleDialogOpen: false,
            schedule: null,
            dateFrom: null,
            dateTo: null,
        };
    }

    uid = () => {
        return Date.now().toString() + Math.floor(Math.random() * 1000).toString();
    }

    getSchedules() {

        if (this.state.dateFrom === null || this.state.dateTo === null) {
            return;
        }

        const { socket } = this.context;
        let belfryID = this.props.belfry.id;

        socket.emit('schedules_get_from_to', this.state.dateFrom, this.state.dateTo, belfryID, (response) => {
            if (response.status) {
                let events = [];
                response.data.forEach((event, i) => {
                    let ev = {
                        id: this.uid(),
                        title: event.start_time + " | " + event.name,
                        // startTime: event.start_time,
                        extendedProps: event,
                        editable: false,
                        startEditable: false,
                        durationEditable: false,
                        resourceEditable: false,
                        display: 'block'
                    }

                    let startTime = event.start_time.split(":");

                    if (event.repeat_type === '0') { //date from to
                        ev.start = new Date(new Date(event.date_from).setHours(startTime[0], startTime[1], 0, 0));
                        let end = new Date(new Date(event.date_to).setHours(0, 0, 0, 0));
                        ev.end = end.setDate(end.getDate() + 1);
                        ev.backgroundColor = '#f64e60';
                        ev.borderColor = '#f64e60';

                    } else if (event.repeat_type === '1') { //every day
                        ev.daysOfWeek = [0, 1, 2, 3, 4, 5, 6];
                        ev.backgroundColor = '#00c5dc';
                        ev.borderColor = '#00c5dc';

                    } else if (event.repeat_type === '2') { //every week at days
                        let days = event.repeat_at.days;
                        let daysOfWeek = days;
                        ev.daysOfWeek = daysOfWeek
                        ev.backgroundColor = '#716aca';
                        ev.borderColor = '#716aca';
                    }
                    else if (event.repeat_type === '3') { //every year

                        ev.backgroundColor = '#1cd561';
                        ev.borderColor = '#1cd561';
                        let dbDate = new Date(event.repeat_at.date);

                        let d = dbDate.toISOString().substring(0, 10);
                        let p = d.split("-");
                        let y = new Date().getFullYear();
                        let dStart = new Date(y, +p[1] - 1, +p[2]).setHours(startTime[0], startTime[1], 0, 0);
                        let dEnd = new Date(y, +p[1] - 1, +p[2] + 1).setHours(0, 0, 0, 0);
                        ev.start = new Date(dStart);
                        ev.end = new Date(dEnd);
                        events.push({ ...ev }); //push current year
                        dStart = new Date(y + 1, +p[1] - 1, +p[2]).setHours(startTime[0], startTime[1], 0, 0);
                        dEnd = new Date(y + 1, +p[1] - 1, +p[2] + 1).setHours(0, 0, 0, 0);
                        ev.id = this.uid();
                        ev.start = new Date(dStart);
                        ev.end = new Date(dEnd);
                        events.push({ ...ev }); //push next year
                        dStart = new Date(y - 1, +p[1] - 1, +p[2]).setHours(startTime[0], startTime[1], 0, 0);
                        dEnd = new Date(y - 1, +p[1] - 1, +p[2] + 1).setHours(0, 0, 0, 0);
                        ev.id = this.uid();
                        ev.start = new Date(dStart);
                        ev.end = new Date(dEnd);
                        events.push({ ...ev }); //push next year
                    }
                    events.push(ev);
                });
                this.setState({
                    events: events
                });
            } else {
                this.setState({
                    events: []
                });
                enqueueSnackbar('Nastala chyba pri načítavaní zvonení', { variant: 'error' });
            }
        });
    }

    componentDidMount() {
        this.getSchedules();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.dateFrom !== prevState.dateFrom || this.state.dateTo !== prevState.dateTo) {
            this.getSchedules();
        }
    }

    handleScheduleDialogFormClose = () => {
        this.setState({
            scheduleDialogFormOpen: false,
            scheduleDialogFormTitle: "Nové zvonenie",
            scheduleDialogOpen: false,
            schedule: null
        });
        this.getSchedules();
    }

    handleScheduleEdit = () => {
        this.setState({
            scheduleDialogFormOpen: true,
            scheduleDialogOpen: false,
            scheduleDialogFormTitle: "Upraviť zvonenie",
        });
    }

    handleScheduleDelete = () => {
        const { socket } = this.context;
        let belfryID = this.props.belfry.id;
        let scheduleID = this.state.schedule.id;
        socket.emit('schedules_delete', scheduleID, belfryID, (response) => {
            if (response.status) {
                enqueueSnackbar('Zvonenie bolo úspešne odstránené', { variant: 'success' });
                this.handleScheduleDialogClose();
            } else {
                enqueueSnackbar('Nastala chyba pri odstraňovaní zvonenia', { variant: 'error' });
            }
        });
    }

    handleScheduleDialogClose = () => {
        this.setState({
            scheduleDialogOpen: false,
            schedule: null
        });
        this.getSchedules();
    }

    render() {
        let calendarProps = {};
        function isDesktop() {
            return window.innerWidth > 768;
        }
        if (!isDesktop()) {
            calendarProps = {
                height: 500,
                aspectRatio: 2,
            }
        }


        return (
            <Box
                component={Card}
                sx={{
                    flexGrow: 1,
                    p: { xs: 1, md: 3 },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                className="calendar_wrapper"
            >
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    events={this.state.events}
                    locale={'sk'}
                    {...calendarProps}
                    headerToolbar={{
                        left: 'prev,next',
                        center: 'title',
                        right: 'newSchedule'
                    }}
                    firstDay={1}
                    datesSet={(info) => {
                        this.setState({
                            dateFrom: info.startStr,
                            dateTo: info.endStr
                        });
                    }}
                    customButtons={{
                        newSchedule: {
                            text: 'Nové zvonenie',
                            click: () => {
                                this.setState({
                                    scheduleDialogFormOpen: true
                                });
                            }
                        }
                    }}
                    eventClick={(info) => {
                        console.log('event click', info);
                        this.setState({
                            scheduleDialogOpen: true,
                            schedule: info.event.extendedProps
                        });
                    }}
                />
                <ScheduleDialog
                    open={this.state.scheduleDialogOpen}
                    belfry={this.props.belfry}
                    onClose={this.handleScheduleDialogClose}
                    schedule={this.state.schedule}
                    onEdit={this.handleScheduleEdit}
                    onDelete={this.handleScheduleDelete}
                />
                <ScheduleDialogForm
                    title={this.state.scheduleDialogFormTitle}
                    belfry={this.props.belfry}
                    open={this.state.scheduleDialogFormOpen}
                    onClose={this.handleScheduleDialogFormClose}
                    schedule={this.state.schedule}
                />
            </Box>
        );
    }
}

export default Calendar;
