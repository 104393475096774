import React from "react";
import { AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem } from "@mui/material";
import Sidemenu from "./Sidemenu";
import { Menu as MenuIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Link } from "react-router-dom";
import { AppContext } from "../../context/App";
import { enqueueSnackbar } from "notistack";

class AppToolbar extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            sideMenuOpen: false,
            drawerWidth: 240,
            menuAnchorEl: null
        };
    }

    handleSideMenuToggle = () => {
        this.setState({ sideMenuOpen: !this.state.sideMenuOpen });
    };

    handleMenuOpen = (event) => {
        this.setState({ menuAnchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ menuAnchorEl: null });
    };

    handleLogout = () => {
        this.setState({ menuAnchorEl: null });
        const { socket, setUser } = this.context;
        socket.emit("logout", (response) => {
            if (response.success) {
                setUser(null);
                enqueueSnackbar('Boli ste úspešne odhlásený', { variant: "success" });
            } else {
                enqueueSnackbar('Nepodarilo sa odhlásiť', { variant: "error" });
            }

        });
    };


    render() {
        const { user, currentBelfryID } = this.context;
        let currentBelfry = user.belfries.find((belfry) => {
            return belfry.id === currentBelfryID;
        });
        //

        function isDesktop() {
            return window.innerWidth > 960;
        }

        let mainBoxMarginLeft = isDesktop() ? this.state.drawerWidth + "px" : 0;
        return (
            <>
                <AppBar position="fixed" sx={{ zIndex: 1201 }} >
                    <Toolbar>
                        <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={this.handleSideMenuToggle}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            eZvonica.sk - {currentBelfry.name}
                        </Typography>
                        <IconButton size="large" edge="end" color="inherit" aria-label="menu" sx={{ ml: 2 }} onClick={this.handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            id="long-menu"
                            anchorEl={this.state.menuAnchorEl}
                            open={this.state.menuAnchorEl ? true : false}
                            onClose={this.handleMenuClose}
                            PaperProps={{
                                sx: {
                                    maxHeight: 48 * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem key={"1"} component={Link} to={"/change-password"}>
                                Zmeniť heslo
                            </MenuItem>
                            <MenuItem key={"2"} onClick={this.handleLogout} >
                                Odhlásiť sa
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar >
                <Box sx={{ flexGrow: 1 }} flexDirection={"row"}>
                    <Sidemenu open={this.state.sideMenuOpen} drawerWidth={this.state.drawerWidth} drawerVariant={isDesktop() ? "permanent" : "temporary"} onClose={this.handleSideMenuToggle} />
                    <Box component={"main"} sx={{ flexGrow: 1, p: { xs: 1, md: 3 }, ml: mainBoxMarginLeft }}>
                        <Toolbar />
                        {this.props.children}
                    </Box>


                </Box >

            </>
        );
    }
}

export default AppToolbar;
