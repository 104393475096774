import react from 'react';
import { AppContext } from '../context/App';
import { Card, CardContent, Typography, Button, TextField } from "@mui/material";
import { enqueueSnackbar } from 'notistack';
import AppToolbar from "./partials/AppToolbar";

class ChangePassword extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: ""
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleSave = () => {
        const { socket } = this.context;
        const { oldPassword, newPassword, newPasswordRepeat } = this.state;
        if (newPassword !== newPasswordRepeat) {
            enqueueSnackbar('Heslá sa nezhodujú', { variant: 'error' });
            return;
        }
        socket.emit("user_change_password", { oldPassword: oldPassword, newPassword: newPassword, confirmPassword: newPasswordRepeat }, (response) => {
            console.log(response);
            if (response.status) {
                enqueueSnackbar('Heslo bolo úspešne zmenené', { variant: 'success' });
                this.setState({
                    oldPassword: "",
                    newPassword: "",
                    newPasswordRepeat: ""
                });
            } else {
                enqueueSnackbar('Heslo sa nepodarilo zmeniť', { variant: 'error' });
            }
        });
    };

    render() {
        return (
            <AppToolbar title="Zmena hesla">
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Zmena hesla
                        </Typography>
                        <Typography variant="body2">
                            Zadajte svoje aktuálne heslo, nové heslo a jeho opakovanie
                        </Typography>
                        <TextField
                            label="Aktuálne heslo"
                            name="oldPassword"
                            type="password"
                            value={this.state.oldPassword}
                            onChange={this.handleChange}
                            fullWidth
                            margin="normal"
                            variant="standard"
                        />
                        <TextField
                            label="Nové heslo"
                            name="newPassword"
                            type="password"
                            value={this.state.newPassword}
                            onChange={this.handleChange}
                            fullWidth
                            margin="normal"
                            variant="standard"
                        />
                        <TextField
                            label="Nové heslo (ešte raz)"
                            name="newPasswordRepeat"
                            type="password"
                            value={this.state.newPasswordRepeat}
                            onChange={this.handleChange}
                            fullWidth
                            margin="normal"
                            variant="standard"
                        />
                        <Button
                            variant="contained"
                            onClick={this.handleSave}
                            sx={{ mt: 2 }}
                        >
                            Uložiť
                        </Button>
                    </CardContent>
                </Card>
            </AppToolbar>
        );
    }
}

export default ChangePassword;
