import react from 'react';
import { AppContext } from '../../context/App';
import { Card, Typography, Box, Switch, List, ListItem, ListItemText, ListItemSecondaryAction, SpeedDial, Paper, Button } from "@mui/material";
import { enqueueSnackbar } from 'notistack';
import { ArrowCircleRight as ArrowCircleRightIcon, ArrowCircleDown as ArrowCircleDownIcon, Add as AddIcon } from '@mui/icons-material';
import ProgramGenerator from './ProgramGenerator';

class Programs extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            programs: [],
            programGeneratorOpen: false,
            edtingProgram: null
        }
    }

    componentDidMount() {
        this.getPrograms();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.belfry.id !== this.props.belfry.id) {
            this.getPrograms();
        }
    }

    getPrograms() {
        const { socket } = this.context;
        let belfryID = this.props.belfry.id;
        socket.emit("programs_get_all_for_belfry", belfryID, (response) => {
            if (response.status) {
                this.setState({ programs: response.data });
            } else {
                enqueueSnackbar('Programy sa nepodarilo načítať', { variant: 'error' });
            }
        });
    }

    handleProgramGeneratorOpen = () => {
        this.setState({ programGeneratorOpen: true });
    }

    handleProgramGeneratorClose = () => {
        this.setState({ programGeneratorOpen: false });
    }

    handleProgramGeneratorSave = () => {
        this.setState({ programGeneratorOpen: false });
        this.getPrograms();
    }

    handleProgramEdit = (program) => {
        this.setState({ edtingProgram: program, programGeneratorOpen: true });
    }


    render() {
        const programs = this.state.programs;

        let belfryBellsNames = this.props.belfry.Bells.reduce((acc, bell) => {
            acc[bell.number] = bell.name;
            return acc;
        }, {});

        return (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {programs.length > 0 && programs.map((program) => {
                        return (
                            <Box component={Paper} key={program.id} sx={{ minWidth: 250, position: 'relative', p: 3, mb: 3 }} >
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography gutterBottom variant="h5" component="div" align="left">
                                        {program.name}
                                    </Typography>
                                    <Box>
                                        <Button variant="contained" color="primary" onClick={() => { this.handleProgramEdit(program) }}>Upraviť</Button>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', overflowX: 'auto' }}>
                                    {Object.keys(program.program).length > 0 && Object.keys(program.program).map((key) => {
                                        let step = program.program[key];
                                        let unit = step.unit;
                                        let duration = step.duration;
                                        let stepName = "Krok " + (parseInt(key));


                                        if (Object.keys(step.bells).length === 0) {
                                            return (
                                                <Box key={key + "_pause"} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: 5 }}>
                                                    <ArrowCircleRightIcon sx={{ fontSize: 50, display: { xs: 'none', md: 'inline-block' } }} color='secondary' />
                                                    <ArrowCircleDownIcon sx={{ fontSize: 50, display: { xs: 'inline-block', md: 'none' } }} color='secondary' />
                                                    <Typography gutterBottom variant="h6" component="div" align="center">
                                                        {duration + " " + unit}
                                                    </Typography>
                                                </Box>
                                            );
                                        }

                                        return (
                                            <Card key={key + "exits"} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: { xs: '100%', md: '250px' }, height: '100%', mr: 2, ml: 2, mb: 2 }}>
                                                <Typography gutterBottom variant="h6" component="div" align="center">
                                                    {stepName}
                                                </Typography>
                                                <Typography gutterBottom variant="h6" component="div" align="center">
                                                    {duration + " " + unit}
                                                </Typography>
                                                <List sx={{ width: '100%' }}>
                                                    {Object.keys(step.bells).map((key) => {
                                                        return (
                                                            <ListItem key={key + "bell"}>
                                                                <ListItemText
                                                                    primary={belfryBellsNames[key]}
                                                                />
                                                                <ListItemSecondaryAction>
                                                                    <Switch
                                                                        edge="end"
                                                                        checked={step.bells[key]}
                                                                        inputProps={{ 'aria-labelledby': 'switch-list-label-bell' }}
                                                                        disabled={true}
                                                                    />
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </Card>
                                        );
                                    })}


                                </Box>
                            </Box>

                        );
                    })
                    }
                </Box>
                <ProgramGenerator open={this.state.programGeneratorOpen} program={this.state.edtingProgram} belfry={this.props.belfry} onClose={this.handleProgramGeneratorClose} onSave={this.handleProgramGeneratorSave} />
                <SpeedDial
                    ariaLabel="add program"
                    sx={{ position: 'absolute', bottom: 25, right: 25 }}
                    icon={<AddIcon />}
                    onClick={this.handleProgramGeneratorOpen}
                />

            </>
        );
    }
}

export default Programs;
