
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";

import { AppContext } from "./context/App";

import Belfry from "./pages/Belfry";
import Login from "./pages/Login";
import ChangePassword from "./pages/ChangePassword";

class App extends React.Component {
  static contextType = AppContext;

  render() {
    let { user, socketUserLoading, currentBelfryID } = this.context;

    if (!user) {
      if (socketUserLoading) {
        return (
          <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} height={'100vh'}>
            <CircularProgress color="primary" size={50} sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
            <Typography variant={"h6"} sx={{ textAlign: 'center', marginTop: 3 }}>Načítavam</Typography>
          </Box>
        );
      } else {
        return <Login />;
      }
    }

    if (!user.belfries || user.belfries.length === 0) {
      return (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} height={'100vh'}>
          <Typography variant={"h6"} sx={{ textAlign: 'center', marginTop: 3 }}>Nemáte žiadne zvonice</Typography>
        </Box>
      );
    }



    if (!currentBelfryID) {
      return (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} height={'100vh'}>
          <CircularProgress color="primary" size={50} sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
          <Typography variant={"h6"} sx={{ textAlign: 'center', marginTop: 3 }}>Načítavam</Typography>
        </Box>
      );
    }

    return (
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<Navigate to={"/belfry/" + currentBelfryID} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/belfry/:id" element={<Navigate to={"/belfry/" + currentBelfryID + '/manual-control'} />} />
          <Route path="/belfry/:id/:page" element={<Belfry />} />
          <Route path="/change-password" element={<ChangePassword />} />
          {/* 
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} /> */}
        </Routes>
      </BrowserRouter>
    )
  }
}

export default App;
