import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './scss/style.scss'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { AppContextProvider } from './context/App';
import { IconButton, Grow } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';


import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/sk';


import SuccessAlert from './alerts/Success';
import ErrorAlert from './alerts/Error';
import WarningAlert from './alerts/Warning';
import InfoAlert from './alerts/Info';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#1565c0',
    },
    secondary: {
      main: '#81c784',
    },
  },
});


function Index() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="sk">
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          timeout={3000}
          action={(key) => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
          Components={{
            success: SuccessAlert,
            error: ErrorAlert,
            warning: WarningAlert,
            info: InfoAlert,
          }}
          TransitionComponent={Grow}
        >

          <AppContextProvider>
            <App />
          </AppContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
