import react from 'react';
import { AppContext } from '../../context/App';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, FormControl, InputLabel, Select, Checkbox, ListItemText, List, ListItem, ListItemSecondaryAction, Switch } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';


class ScheduleDialogForm extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            schedule: {
                name: "",
                repeat_type: 0,
                from_date: "",
                to_date: "",
                pick_days: [],
                pick_date: "",
                start_time: "",
                program: "",
                duration: 0,
                unit: "m",
                bells: []
            },
            programs: [],
            days: [
                { value: 0, label: "Nedeľa" },
                { value: 1, label: "Pondelok" },
                { value: 2, label: "Utorok" },
                { value: 3, label: "Streda" },
                { value: 4, label: "Štvrtok" },
                { value: 5, label: "Piatok" },
                { value: 6, label: "Sobota" },
            ],
            inputValid: {
                name: false,
                repeat_type: true,
                from_date: false,
                to_date: false,
                pick_days: false,
                pick_date: false,
                start_time: false,
                program: false,
                duration: false,
                unit: false,
                bells: false
            }

        };
    }


    componentDidMount() {
        const { socket } = this.context;
        socket.emit("programs_get_all_for_belfry", this.props.belfry.id, (response) => {

            if (response.status) {
                this.setState({ programs: response.data });
            } else {
                console.error(response);
                enqueueSnackbar('Programy sa nepodarilo načítať', { variant: 'error' });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.schedule !== prevProps.schedule && this.props.schedule !== null) {
            console.log('schedule edit', this.props.schedule);
            let unit = "s";
            let duration = this.props.schedule.duration;
            if (this.props.schedule.duration % 60 === 0) {
                unit = "m";
                duration = duration / 60;
            }
            let bells = [];
            if (this.props.schedule.bells) {
                Object.keys(this.props.schedule.bells).forEach((key) => {
                    let bellNumber = key.split("_")[1];
                    if (this.props.schedule.bells[key])
                        bells.push(+bellNumber);
                });
            }

            let start_time = moment();
            let dbStart_time = this.props.schedule.start_time.split(":");
            start_time.set({ hour: dbStart_time[0], minute: dbStart_time[1], second: 0, millisecond: 0 });


            console.log('bells', bells);
            this.setState({
                schedule: {
                    name: this.props.schedule.name,
                    repeat_type: +this.props.schedule.repeat_type,
                    from_date: moment(this.props.schedule.date_from),
                    to_date: moment(this.props.schedule.date_to),
                    pick_days: this.props.schedule.repeat_at?.days,
                    pick_date: moment(this.props.schedule.repeat_at?.date),
                    start_time: start_time,
                    program: this.props.schedule.Program ? this.props.schedule.Program.id : "-1",
                    duration: duration,
                    unit: unit,
                    bells: bells
                }
            });

            this.revalidateAllInputs();
        }
    }

    revalidateAllInputs = () => {
        let i = 0;
        Object.keys(this.state.inputValid).forEach((key) => {

            i++;
            //cannot call validateInput directly because validateInput is async
            setTimeout(() => {
                this.validateInput(key, this.state.schedule[key]);
            }, 50 * i);

        });
    }

    handleInputChange = (event) => {
        this.setState({
            schedule: {
                ...this.state.schedule,
                [event.target.name]: event.target.value
            }
        });

        this.validateInput(event.target.name, event.target.value);

    };

    handleBellChange = (bellNumber) => {
        const currentIndex = this.state.schedule.bells.indexOf(bellNumber);
        const newChecked = [...this.state.schedule.bells];

        if (currentIndex === -1) {
            newChecked.push(bellNumber);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.validateInput("bells", newChecked);

        this.setState({
            schedule: {
                ...this.state.schedule,
                bells: newChecked
            }
        });
    };


    validateInput = (name, value) => {
        let valid = false;
        let today = new Date();
        today.setHours(0, 0, 0, 0);


        if (value) {
            switch (name) {
                case "name":
                    valid = value.length > 0;
                    break;
                case "repeat_type":
                    valid = true;
                    break;
                case "from_date":
                    //valid is if date is in future or today
                    valid = value >= today;
                    break;
                case "to_date":
                    //valid is if date is in future or today and is after from_date
                    valid = value >= today && value >= this.state.schedule.from_date;
                    break;
                case "pick_days":
                    valid = value.length > 0;
                    break;
                case "pick_date":
                    valid = value >= today;
                    break;
                case "start_time":
                    //valid if time is set
                    valid = value !== null;
                    break;
                case "program":
                    valid = value > 0 || value === "-1";
                    break;
                case "duration":
                    valid = value > 0;
                    break;
                case "unit":
                    valid = true;
                    break;
                case "bells":
                    valid = value.length > 0;
                    break;
                default:
                    valid = false;
                    break;
            }
        } else {
            valid = false;
        }

        this.setState({
            inputValid: {
                ...this.state.inputValid,
                [name]: valid
            }
        });
    }


    handleUnitChange = (event) => {
        this.setState({
            schedule: {
                ...this.state.schedule,
                unit: event.target.value,
                duration: 0
            }
        });
    };

    handleClose = () => {
        this.setState({
            schedule: {
                name: "",
                repeat_type: 0,
                from_date: "",
                to_date: "",
                pick_days: [],
                pick_date: "",
                start_time: "",
                program: "",
                duration: 0,
                unit: "m",
                bells: []
            }
        });

        this.props.onClose();
    };

    handleSave = () => {
        let { socket } = this.context;
        let valid = false;

        valid = this.state.inputValid.name;

        switch (this.state.schedule.repeat_type) {
            case 0:
                valid = valid && this.state.inputValid.from_date && this.state.inputValid.to_date;
                break;
            case 2:
                valid = valid && this.state.inputValid.pick_days;
                break;
            case 3:
                valid = valid && this.state.inputValid.pick_date;
                break;
            default:
                break;
        }

        valid = valid && this.state.inputValid.start_time;

        if (this.state.schedule.program === "-2") {
            valid = valid && this.state.inputValid.duration && this.state.inputValid.unit && this.state.inputValid.bells;
        } else {
            valid = valid && this.state.inputValid.program;
        }

        if (valid) {
            let schedule_form = {
                name: this.state.schedule.name,
                repeat_type: this.state.schedule.repeat_type,
                start_time: this.state.schedule.start_time.format("HH:mm"),
            }

            switch (this.state.schedule.repeat_type) {
                case 0:
                    schedule_form.date_from = this.state.schedule.from_date.toISOString(true).substring(0, 23) + "Z";
                    schedule_form.date_to = this.state.schedule.to_date.toISOString(true).substring(0, 23) + "Z";
                    break;
                case 2:
                    schedule_form.repeat_at = {
                        days: this.state.schedule.pick_days
                    }
                    break;
                case 3:
                    schedule_form.repeat_at = {
                        date: this.state.schedule.pick_date.toISOString(true).substring(0, 23) + "Z"
                    }
                    break;
                default:
                    break;
            }

            if (this.state.schedule.program === "-1") {
                let duration = this.state.schedule.unit === "m" ? this.state.schedule.duration * 60 : this.state.schedule.duration;
                schedule_form.program = null;
                schedule_form.bells = {};
                //schedule_form.bells = this.state.schedule.bells;
                for (let i = 0; i < this.props.belfry.Bells.length; i++) {
                    let b = { ["bell_" + this.props.belfry.Bells[i].number]: this.state.schedule.bells.indexOf(this.props.belfry.Bells[i].number) !== -1 };
                    schedule_form.bells = { ...schedule_form.bells, ...b };
                }

                schedule_form.duration = duration;
            } else {
                schedule_form.program = this.state.schedule.program;
            }

            schedule_form.belfry = this.props.belfry.id;

            let action = "schedules_add_new";

            if (this.props.schedule) {
                schedule_form.id = this.props.schedule.id;
                action = "schedules_update";
            }

            socket.emit(action, schedule_form, (response) => {
                if (response.status) {
                    this.handleClose();
                    if (this.props.onSave) {
                        this.props.onSave();
                    }
                    enqueueSnackbar("Zvonenie bolo úspešne uložené", { variant: "success" });
                } else {
                    enqueueSnackbar("Nastala chyba pri ukladaní", { variant: "error" });
                }
            });

        } else {
            enqueueSnackbar("Niektoré polia nie sú vyplnené správne", { variant: "error" });
        }
    };



    render() {
        return (
            <Dialog open={this.props.open} onClose={this.handleClose}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Názov zvonenia"
                        name="name"
                        value={this.state.schedule.name}
                        onChange={this.handleInputChange}
                        fullWidth
                        required
                        error={!this.state.inputValid.name}
                        sx={{ mb: 2, mt: 2 }}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="repeat-type-label">Typ opakovania</InputLabel>
                        <Select
                            labelId="repeat-type-label"
                            id="repeat-type"
                            name="repeat_type"
                            value={this.state.schedule.repeat_type}
                            onChange={this.handleInputChange}
                            label="Typ opakovania"
                            error={this.state.inputValid.repeat_type === null}
                            sx={{ mb: 2 }}
                        >
                            <MenuItem value={0}>Od - do</MenuItem>
                            <MenuItem value={1}>Každý deň</MenuItem>
                            <MenuItem value={2}>Každý týždeň</MenuItem>
                            <MenuItem value={3}>Každý rok</MenuItem>
                        </Select>
                    </FormControl>
                    {this.state.schedule.repeat_type === 0 && (
                        <>
                            <DatePicker
                                label="Od"
                                name="from_date"
                                value={this.state.schedule.from_date}
                                onChange={(newValue) => {
                                    console.log(newValue);
                                    this.setState({
                                        schedule: {
                                            ...this.state.schedule,
                                            from_date: newValue
                                        }
                                    });
                                    this.validateInput("from_date", newValue);
                                }}
                                error={!this.state.inputValid.from_date}
                                textField={(params) => <TextField {...params} />}
                                sx={{ mb: 2, width: "100%" }}
                            />

                            <DatePicker
                                label="Do"
                                name="to_date"
                                value={this.state.schedule.to_date}
                                onChange={(newValue) => {
                                    this.setState({
                                        schedule: {
                                            ...this.state.schedule,
                                            to_date: newValue
                                        }
                                    });
                                    this.validateInput("to_date", newValue);
                                }}
                                error={!this.state.inputValid.to_date}
                                textField={(params) => <TextField {...params} />}
                                sx={{ mb: 2, width: "100%" }}
                            />
                        </>
                    )}
                    {this.state.schedule.repeat_type === 2 && (
                        <FormControl fullWidth>
                            <InputLabel id="pick-days-label">Vyberte dni v týždni</InputLabel>
                            <Select
                                labelId="pick-days-label"
                                id="pick-days"
                                name="pick_days"
                                value={this.state.schedule.pick_days}
                                onChange={this.handleInputChange}
                                label="Vyberte dni v týždni"
                                multiple
                                error={!this.state.inputValid.pick_days}
                                sx={{ mb: 2 }}
                                renderValue={(selected) => {
                                    const selectedDays = this.state.days.filter((day) => selected.indexOf(day.value) > -1);
                                    return selectedDays.map((day) => day.label).join(", ");
                                }}
                            >
                                {this.state.days.map((day) => (
                                    <MenuItem key={day.value} value={day.value}>
                                        <Checkbox checked={this.state.schedule.pick_days.indexOf(day.value) > -1} />
                                        <ListItemText primary={day.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {this.state.schedule.repeat_type === 3 && (
                        <DatePicker
                            label="Vyberte deň v roku"
                            name="pick_date"
                            value={this.state.schedule.pick_date}
                            error={!this.state.inputValid.pick_date}
                            onChange={(newValue) => {
                                this.setState({
                                    schedule: {
                                        ...this.state.schedule,
                                        pick_date: newValue
                                    }
                                });
                                this.validateInput("pick_date", newValue);
                            }}
                            textField={(params) => <TextField {...params} />}
                            sx={{ mb: 2, width: "100%" }}
                        />

                    )}
                    <TimePicker
                        label="Začiatok zvonenia"
                        name="start_time"
                        value={this.state.schedule.start_time}
                        onChange={(newValue) => {
                            this.setState({
                                schedule: {
                                    ...this.state.schedule,
                                    start_time: newValue
                                }
                            });
                            this.validateInput("start_time", newValue);
                        }}
                        error={!this.state.inputValid.start_time}
                        textField={(params) => <TextField {...params} />}
                        sx={{ mb: 2, width: "100%" }}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="program-label">Program</InputLabel>
                        <Select
                            labelId="program-label"
                            id="program"
                            name="program"
                            value={this.state.schedule.program}
                            onChange={this.handleInputChange}
                            label="Program"
                            error={!this.state.inputValid.program}
                            sx={{ mb: 2 }}
                        >
                            <MenuItem value="-1">Bez programu</MenuItem>
                            {this.state.programs.map((program) => (
                                <MenuItem key={program.id} value={program.id}>{program.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {this.state.schedule.program === "-1" && (
                        <>
                            <TextField
                                label="Dĺžka zvonenia"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                required
                                sx={{ mb: 2 }}
                                name="duration"
                                value={this.state.schedule.duration}
                                onChange={this.handleInputChange}
                                error={!this.state.inputValid.duration}
                                InputProps={{
                                    endAdornment:
                                        <TextField
                                            size="small"
                                            select
                                            value={this.state.schedule.unit}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant='standard'
                                            sx={{ width: '75px' }}
                                            onChange={this.handleUnitChange}
                                        >
                                            <option value="s">sek</option>
                                            <option value="m">min</option>
                                        </TextField>

                                }}
                            />

                            <List component="ul">
                                {this.props.belfry.Bells.map((bell) => {
                                    return (
                                        <ListItem key={bell.name}>
                                            <ListItemText primary={bell.name} />
                                            <ListItemSecondaryAction>
                                                <Switch
                                                    edge="end"
                                                    onChange={(event) => this.handleBellChange(bell.number)}
                                                    checked={this.state.schedule.bells.indexOf(bell.number) !== -1}
                                                    inputProps={{ 'aria-labelledby': bell.number }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Zrušiť
                    </Button>
                    <Button onClick={this.handleSave} color="primary">
                        Uložiť
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ScheduleDialogForm;

