import react from 'react';
import { AppContext } from '../../context/App';
import { Card, CardHeader, Typography, Paper, Button, IconButton, TextField, Switch, List, ListItem, ListItemText, ListItemSecondaryAction, Dialog, DialogTitle, DialogContent, DialogActions, Box } from "@mui/material";
import { enqueueSnackbar } from 'notistack';
import { CloseOutlined as CloseIcon } from '@mui/icons-material';

class ProgramGenerator extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            steps: {},
            name: "",
        }
    }

    componentDidMount() {
        if (this.props.program) {
            this.setState({
                steps: this.props.program.program,
                name: this.props.program.name
            });
        } else {
            this.handleAddStep();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.program !== this.props.program) {
            if (this.props.program) {
                this.setState({
                    steps: this.props.program.program,
                    name: this.props.program.name
                });
            }
        } else if (prevProps.open !== this.props.open) {
            this.setState({
                steps: {},
                name: ""
            }, () => {
                this.handleAddStep();
            });
        }
    }

    handleStepDurationChange = (step, duration) => {
        let steps = { ...this.state.steps };
        steps[step].duration = duration;
        this.setState({ steps: steps });
    }

    handleStepBellChange = (step, bell, enabled) => {
        let steps = { ...this.state.steps };
        steps[step].bells[bell] = enabled;
        this.setState({ steps: steps });
    }

    handleStepUnitChange = (step, unit) => {
        let steps = { ...this.state.steps };
        steps[step].unit = unit;
        steps[step].duration = 0;
        this.setState({ steps: steps });
    }

    handleRemoveStep = (step) => {
        let steps = { ...this.state.steps };
        if (Object.keys(steps).length === 1) {
            enqueueSnackbar('Program musí mať aspoň jeden krok', { variant: 'error' });
            return;
        }

        delete steps[step];

        steps = Object.keys(steps).reduce((result, key, index) => {
            result[index + 1] = steps[key];
            return result;
        }, {});
        this.setState({ steps: steps });
    }

    handleAddStep = () => {
        this.addStep(60, "s", this.props.belfry.Bells.reduce((result, bell, index) => {
            result[index] = false;
            return result;
        }, {}));
    }

    handleAddStepPause = () => {
        this.addStep(60, "s", {});
    }

    addStep = (duration, unit, bells) => {
        let steps = { ...this.state.steps };
        steps[Object.keys(steps).length + 1] = {
            duration: duration,
            unit: unit, // s, m
            bells: bells
        };
        this.setState({ steps: steps });
    }

    handleSave = () => {
        const { socket } = this.context;

        let data = {
            name: this.state.name,
            program: this.state.steps,
            belfryID: this.props.belfry.id
        };

        let action = 'programs_add_new';

        if (this.props.program) {
            data.id = this.props.program.id;
            action = 'programs_update';
        }

        console.log('sending data with action: ', action, data);

        socket.emit(action, data, (response) => {
            if (response.status) {
                enqueueSnackbar('Program bol úspešne uložený', { variant: 'success' });
                if (this.props.onClose) {
                    this.props.onClose();
                }

            } else {
                enqueueSnackbar('Program sa nepodarilo uložiť', { variant: 'error' });
            }
        });

        this.setState({
            steps: {},
            name: "",
        });

        if (this.props.onSave) {
            this.props.onSave();
        }
    }

    handleClose = () => {
        this.setState({
            steps: {},
            name: "",
        });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }


    render() {
        const steps = this.state.steps;

        return (
            <Dialog open={this.props.open} onClose={this.handleClose}>
                <DialogTitle>Program</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <TextField
                            label="Názov programu"
                            fullWidth
                            required
                            sx={{ mb: 2, mt: 2, ml: 2, mr: 2, width: 'calc(100% - 32px)' }}
                            name="name"
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                        />
                    </Box>
                    {Object.keys(steps).map((step) => {
                        return (
                            <Card component={Paper} key={step} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                                <CardHeader
                                    title={`Krok ${step}`}
                                    sx={{ width: '100%' }}
                                    action={
                                        <IconButton aria-label="delete" color="error" onClick={() => this.handleRemoveStep(step)}>
                                            <CloseIcon />
                                        </IconButton>
                                    }
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <TextField
                                        label="Dĺžka zvonenia"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        required
                                        sx={{ mb: 2, mt: 2, ml: 2, mr: 2, width: 'calc(100% - 32px)' }}
                                        name="duration"
                                        value={steps[step].duration}
                                        onChange={(e) => this.handleStepDurationChange(step, e.target.value)}
                                        InputProps={{
                                            endAdornment:
                                                <TextField
                                                    size="small"
                                                    select
                                                    value={steps[step].unit}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant='standard'
                                                    sx={{ width: '75px' }}
                                                    onChange={(e) => this.handleStepUnitChange(step, e.target.value)}
                                                >
                                                    <option value="s">sek</option>
                                                    <option value="m">min</option>
                                                </TextField>

                                        }}
                                    />
                                    {Object.keys(steps[step].bells).length > 0 &&

                                        <List component="ul" sx={{ width: '100%' }}>
                                            {this.props.belfry.Bells.map((bell) => {
                                                return (
                                                    <ListItem key={bell.name} sx={{ width: '100%' }}>
                                                        <ListItemText primary={bell.name} />
                                                        <ListItemSecondaryAction>
                                                            <Switch
                                                                edge="end"
                                                                onChange={(e) => this.handleStepBellChange(step, bell.number, e.target.checked)}
                                                                checked={steps[step].bells[bell.number]}
                                                                inputProps={{ 'aria-labelledby': bell.number }}
                                                                sx={{ ml: 8 }}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    }
                                    {Object.keys(steps[step].bells).length === 0 &&
                                        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>Pauza</Typography>
                                    }
                                </Box>
                            </Card>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={this.handleClose}>Zavrieť</Button>
                    <Button variant="contained" onClick={this.handleAddStep}>Pridať krok</Button>
                    <Button variant="contained" onClick={this.handleAddStepPause}>Pridať pauzu</Button>
                    <Button variant="contained" onClick={this.handleSave}>Uložiť</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ProgramGenerator;




