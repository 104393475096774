import react from 'react';
import { AppContext } from '../../context/App';
import { Card, CardContent, Typography, Button, TextField, Box } from "@mui/material";
import { enqueueSnackbar } from 'notistack';

class BelfryConfig extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.setState(
            { ...this.props.belfry.config }
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.belfry.config !== this.props.belfry.config) {
            this.setState(
                { ...this.props.belfry.config }
            );
        }
    }

    handleConfigChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSave = (cb) => {
        if (typeof cb !== "function") cb = () => { };

        const { socket } = this.context;
        let config = this.state;
        let belfryID = this.props.belfry.id;

        socket.emit("belfry_update_config", belfryID, config, (response) => {
            if (response.status) {
                enqueueSnackbar("Konfigurácia bola úspešne uložená", { variant: "success" });
                cb();
            } else {
                enqueueSnackbar("Konfiguráciu sa nepodarilo uložiť", { variant: "error" });
            }
        });
    }

    handleSaveAndSend = () => {
        this.handleSave(() => {
            const { socket } = this.context;
            let belfryID = this.props.belfry.id;

            socket.emit("belfry_send_config", belfryID, (response) => {
                if (response.success) {
                    enqueueSnackbar("Konfigurácia bola úspešne odoslaná", { variant: "success" });
                } else {
                    enqueueSnackbar("Konfiguráciu sa nepodarilo odoslať", { variant: "error" });
                }
            });
        });
    }

    render() {
        const { user } = this.context;
        if (user.role !== "administrator") return (
            <Card sx={{ width: '100%' }}>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Konfigurácia
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Nemáte oprávnenie na zobrazenie tejto stránky
                    </Typography>
                </CardContent>
            </Card>
        );

        let mainSettings = [];
        let bellSettings = [];

        const pushTo = (array, key) => {
            array.push(
                <TextField
                    sx={{ flexGrow: 1, ml: { xs: 0, md: 2 }, mb: 2, maxWidth: { xs: '100vw', md: '33%' }, minWidth: 200, width: '100%' }}
                    name={key}
                    key={key}
                    label={key}
                    size="small"
                    value={this.state[key]}
                    onChange={this.handleConfigChange}
                />
            );
        }


        if (Object.keys(this.state).length === 0) return null;
        Object.keys(this.state).forEach((key) => {
            let bellNumber = key.match(/bell(\d+)/);

            if (key.startsWith("bell")) {
                if (bellSettings[bellNumber] === undefined) bellSettings[bellNumber] = [];
                pushTo(bellSettings[bellNumber], key);
            } else {
                pushTo(mainSettings, key);
            }
        });

        return (
            <Card sx={{ width: '100%' }}>
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, flexWrap: 'wrap' }}>
                        {mainSettings}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, flexWrap: 'wrap' }}>

                        {Object.keys(bellSettings).map((key, index) => {
                            return (
                                <Card key={key} sx={{ flexGrow: 1, ml: { xs: 0, md: 2 }, mb: 2, maxWidth: { xs: '100vw', md: '33%' }, minWidth: 200, width: '100%' }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                                            Zvon {key.match(/bell(\d+)/)[1]}
                                        </Typography>
                                        {bellSettings[key]}
                                    </CardContent>
                                </Card>
                            );
                        })}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, flexWrap: 'wrap' }}>

                        <Button variant="contained" onClick={this.handleSave} sx={{ ml: { xs: 0, md: 2 } }}>Uložiť</Button>
                        <Button variant="contained" onClick={this.handleSaveAndSend} sx={{ ml: 2 }}>Uložiť a odoslať</Button>
                    </Box>
                </CardContent>
            </Card>
        );
    }
}


class BelfrySettings extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Box component={"div"} sx={{ flexGrow: 1, width: '100%', mb: 4, flexDirection: 'column' }} display={"flex"}>
                <BelfryConfig belfry={this.props.belfry} />
            </Box>
        );
    }
}

export default BelfrySettings;
