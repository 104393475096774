import React from "react";
import { Button, Container, Box, TextField, Typography, Backdrop, CircularProgress } from "@mui/material";


import { AppContext } from "./../context/App";

class Login extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    handleSubmit = (event) => {
        let { socket, setUser } = this.context;
        event.preventDefault();
        this.setState({ isLoading: true });
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get("email"),
            password: data.get("password"),
        });

        if (data.get("email") !== "" && data.get("password") !== "") {
            fetch("/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    email: data.get("email"),
                    password: data.get("password"),
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("Success:", data);

                    //reconnect socket so it is connected with session
                    socket.disconnect();
                    socket.connect();

                    socket.emit("getUser", (data) => {
                        console.log("getUser:", data);
                        setUser(data);
                        this.setState({ isLoading: false });
                    });


                })
                .catch((error) => {
                    console.error("Error:", error);
                    this.setState({ isLoading: false });
                });
        } else {
            this.setState({ isLoading: false });
        }
    };



    render() {
        return (
            <>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>


                <Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Prihlásenie do systému eZvonica.sk
                        </Typography>
                        <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Heslo"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Prihlásiť sa
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </>
        );
    }
}

export default Login;
