import React from "react";
import { Box, Paper, Typography } from "@mui/material";

import { AppContext } from "../context/App";
import { useParams, useNavigate } from "react-router-dom";

import AppToolbar from "./partials/AppToolbar";
import Bell from "./partials/Bell";
import ClosestRingings from "./partials/ClosestRingings";
import ManualControl from "./partials/ManualControl";
import Calendar from "./partials/Calendar";
import Programs from "./partials/Programs";
import BelfrySettings from "./partials/BelfrySettings";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

class Belfry extends React.Component {
    static contextType = AppContext;

    render() {
        let { user, bellsStates, currentBelfryID, setCurrentBelfryID } = this.context;
        let { params } = this.props;
        let page = params.page;
        if (!page) page = "manual-control";

        if (user.belfries.length === 0) {
            return (
                <AppToolbar>
                    <Box component={Paper} sx={{ flexGrow: 1, p: { xs: 0, md: 3 }, width: '100%' }} display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <Typography variant={"h6"} sx={{ textAlign: 'center', marginTop: 3 }}>Nemáte žiadne zvonice</Typography>
                    </Box>
                </AppToolbar>
            );
        }

        let belfries = user.belfries;

        //get current belfry
        let currentBelfry = belfries.find((belfry) => {
            return belfry.id === params.id;
        });

        if ((!currentBelfryID && currentBelfry.id) || (currentBelfry.id !== currentBelfryID)) {
            setCurrentBelfryID(currentBelfry.id);
        }


        return (
            <AppToolbar>
                {page === "manual-control" &&


                    <Box component={"div"} sx={{ flexGrow: 1, width: '100%', mb: 4, flexDirection: { xs: 'column', md: 'row' } }} display={"flex"} justifyContent={"space-between"}>
                        {currentBelfry.Bells.map((bell) => {
                            let bellState = "-2";

                            if (bellsStates[currentBelfry.id]) {
                                if (bellsStates[currentBelfry.id][bell.number] !== undefined) {
                                    bellState = bellsStates[currentBelfry.id][bell.number];
                                }
                            }

                            return (
                                <Bell key={bell.name} name={bell.name} number={bell.number} id={bell.id} size={bell.size} belfryID={currentBelfry.id} state={bellState} />
                            );
                        })
                        }
                        <ClosestRingings belfry={currentBelfry} />
                        <ManualControl belfry={currentBelfry} bellStates={bellsStates[currentBelfry.id]} />

                    </Box>
                }

                {page === "programs" &&
                    <Programs belfry={currentBelfry} />
                }

                {page === "planning" &&
                    <Box component={Paper} sx={{ flexGrow: 1, p: { xs: 0, md: 3 }, width: '100%' }} display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <Calendar belfry={currentBelfry} />
                    </Box>
                }

                {page === "settings" &&
                    <BelfrySettings belfry={currentBelfry} />
                }
            </AppToolbar>
        );
    }
}

export default withParams(Belfry);
