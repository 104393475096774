import react from 'react';
import { Drawer, Toolbar, List, ListItemButton, ListItemIcon, ListItemText, Collapse, ListItemSecondaryAction, Chip, CircularProgress, Box } from '@mui/material';
import { Doorbell as DoorbellIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { AppContext } from '../../context/App';
import { useParams, useNavigate } from "react-router-dom";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}


class Sidemenu extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            connectionsStatuses: {}
        };
    }

    handleBelfryClick(belfryID) {
        const { setCurrentBelfryID } = this.context;
        setCurrentBelfryID(belfryID);
    }

    getLastBelfriesConnectionsStatuses() {
        const { user, socket } = this.context;
        user.belfries.forEach((belfry) => {
            socket.emit("getLastBelfryConnectionStatus", { belfryID: belfry.id }, (data) => {
                this.setState({
                    connectionsStatuses: {
                        ...this.state.connectionsStatuses,
                        [belfry.id]: data
                    }
                });
            });
        });
    }

    componentDidMount() {
        this.getLastBelfriesConnectionsStatuses();

        const { socket } = this.context;
        socket.on("belfry_connection_status", (data) => {
            let connectionsStatuses = this.state.connectionsStatuses;
            connectionsStatuses[data.belfryID] = data.state;
            this.setState({ connectionsStatuses: connectionsStatuses });
        });
    }

    getChip(status) {
        if (status === true) {
            return <Chip label="Online" size="small" color="success" />;
        } else if (status === false) {
            return <Chip label="Offline" size="small" color="error" />;
        } else if (status === undefined) {
            return <CircularProgress size={15} color='primary' />;
        } else {
            return <Chip label="Neznámy" size="small" color="warning" />;
        }
    }

    handleSchedulesGenerate = () => {
        const { socket } = this.context;
        socket.emit("generate_schedules", (response) => {
            if (response.success) {
                enqueueSnackbar('Zvonenia boli úspešne vygenerované', { variant: 'success' });
            } else {
                enqueueSnackbar('Zvonenia sa nepodarilo vygenerovať', { variant: 'error' });
            }
        });
    }

    render() {
        const { user, currentBelfryID } = this.context;
        const belfries = user.belfries;
        let { params } = this.props;
        let page = params.page;

        return (
            <Drawer open={this.props.open} variant={this.props.drawerVariant} onClose={this.props.onClose}
                sx={{
                    width: this.props.drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: this.props.drawerWidth, boxSizing: 'border-box' },
                }} >
                <Toolbar />
                <List>
                    {belfries.map((belfry) => {
                        return (
                            <Box key={belfry.id + "_menu_button"}>
                                <ListItemButton

                                    component={Link}
                                    to={"/belfry/" + belfry.id}
                                    onClick={() => {
                                        this.handleBelfryClick(belfry.id);
                                    }}
                                    selected={belfry.id === currentBelfryID}
                                >
                                    <ListItemIcon>
                                        <DoorbellIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={belfry.name} />
                                    <ListItemSecondaryAction>
                                        {this.getChip(this.state.connectionsStatuses[belfry.id])}
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                                <Collapse in={belfry.id === currentBelfryID} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to={"/belfry/" + belfry.id + "/manual-control"} selected={page === "manual-control"} onClick={this.props.onClose}>
                                            <ListItemText primary="Manuále ovládanie" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to={"/belfry/" + belfry.id + "/programs"} selected={page === "programs"} onClick={this.props.onClose}>
                                            <ListItemText primary="Programy" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to={"/belfry/" + belfry.id + "/planning"} selected={page === "planning"} onClick={this.props.onClose}>
                                            <ListItemText primary="Plánovanie" />
                                        </ListItemButton>

                                        {user.role === "administrator" &&
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to={"/belfry/" + belfry.id + "/settings"} selected={page === "settings"} onClick={this.props.onClose}>
                                                <ListItemText primary="Nastavenia" />
                                            </ListItemButton>
                                        }
                                    </List>
                                </Collapse>
                            </Box>

                        );
                    })
                    }
                </List>
                <List sx={{ mt: 'auto' }}>
                    {user.role === "administrator" &&
                        <ListItemButton onClick={this.handleSchedulesGenerate}>
                            <ListItemText primary="Generovať zvonenia" />
                        </ListItemButton>
                    }
                </List>

            </Drawer>



        );
    }
}

export default withParams(Sidemenu);
