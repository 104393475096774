import react from 'react';
import { AppContext } from '../../context/App';
import { Card, CardContent, Typography, Button, TextField, Switch, List, ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
import { enqueueSnackbar } from 'notistack';


class ManualControl extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            checked: [],
            unit: "m",
            time: 0

        };
    }

    handleToggle = (value) => () => {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = [...this.state.checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked,
        });
    };

    handleUnitChange = (event) => {
        this.setState({
            unit: event.target.value,
            time: 0
        });
    };

    handleBellsStatesChangne = (bellsStates) => {
        let checked = [];
        for (let i = 0; i < bellsStates.length; i++) {
            if (bellsStates[i] === (this.context.bellStates.RINGING || this.context.bellStates.STARTING)) {
                checked.push(i);
            }
        }
        this.setState({
            checked: checked
        });
    };


    handleRing = () => {
        let time = this.state.time;
        if (this.state.unit === "m") {
            time = time * 60;
        }

        if (time <= 0) {
            enqueueSnackbar("Zadaná dĺžka zvonenia musí byť väčšia ako 0", { variant: "error" });
            return;
        }

        if (this.state.checked.length === 0) {
            enqueueSnackbar("Nevybrali ste žiadny zvon", { variant: "error" });
            return;
        }

        this.context.socket.emit("belfryRingBell",
            {
                belfryID: this.props.belfry.id,
                bells: this.state.checked,
                time: time,
                action: true
            },
            (response) => {
                if (response.success) {
                    enqueueSnackbar("Zvonenie bolo úspešne spustené", { variant: "success" });

                } else {
                    if (response.message == "no_bells_selected") {
                        enqueueSnackbar("Nevybrali ste žiadny zvon", { variant: "error" });
                    } else {
                        enqueueSnackbar("Nastala chyba pri spúšťaní zvonenia", { variant: "error" });
                    }
                }
            }
        );
    };

    render() {
        return (
            <Card sx={{ mb: { xs: 2, md: 0 }, flex: 1, m: '10px' }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" align="center">
                        Ručné ovládanie
                    </Typography>
                </CardContent>
                <CardContent>
                    <List component="ul">
                        {this.props.belfry.Bells.map((bell) => {
                            return (
                                <ListItem key={bell.name}>
                                    <ListItemText primary={bell.name} />
                                    <ListItemSecondaryAction>
                                        <Switch
                                            edge="end"
                                            onChange={this.handleToggle(bell.number)}
                                            checked={this.state.checked.indexOf(bell.number) !== -1}
                                            inputProps={{ 'aria-labelledby': bell.number }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                    <TextField label="Dĺžka zvonenia"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={this.state.time}
                        onChange={(e) => {
                            this.setState({
                                time: e.target.value
                            });
                        }}
                        InputProps={{
                            endAdornment:
                                <TextField
                                    size="small"
                                    select
                                    value={this.state.unit}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant='standard'
                                    sx={{ width: '75px' }}
                                    onChange={this.handleUnitChange}
                                >
                                    <option value="s">sek</option>
                                    <option value="m">min</option>
                                </TextField>

                        }}
                    />
                    <Button fullWidth size="small" variant='contained' sx={{ mt: 2 }} onClick={this.handleRing}>Zvoniť</Button>

                </CardContent>
            </Card>
        );
    }
}

export default ManualControl;

