import react from 'react';
import { AppContext } from '../../context/App';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, ListItemIcon, Divider } from '@mui/material';

import { Replay as ReplayIcon, CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { typography } from '@mui/system';

class ScheduleDialog extends react.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const schedule = this.props.schedule ? { ...this.props.schedule } : null;

        const { days } = this.context;

        let repeatAtDays = [];
        if (schedule && schedule.repeat_type === "2") {
            if (schedule.repeat_at.days) {
                for (let i = 0; i < schedule.repeat_at.days.length; i++) {
                    repeatAtDays[i] = days[schedule.repeat_at.days[i]];
                }

            }
        }

        if (schedule && schedule.Program !== null) {
            if (typeof schedule.Program === 'string') {
                schedule.Program = JSON.parse(schedule.Program);
            }
        }


        let bells = [];
        let belfryBells = this.props.belfry.Bells;
        if (schedule && schedule.Program === null) {
            if (typeof schedule.bells === 'string') {
                schedule.bells = JSON.parse(schedule.bells);
            }

            Object.keys(schedule.bells).forEach((key) => {
                let bellNumber = key.split('_')[1];
                let bell = belfryBells.find(b => b.number === +bellNumber);
                bells.push(bell.name);
            });
        }


        return (
            <Dialog open={this.props.open} onClose={this.props.onClose} PaperProps={{ sx: { minWidth: { xs: '90vw', md: '500px' } } }}>
                {schedule &&
                    <>
                        <DialogTitle>{schedule.name}</DialogTitle>
                        <DialogContent>
                            <List>
                                {schedule.repeat_type === "0" &&
                                    <>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ReplayIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="V rozmedzí dátumov od do" secondary="Typ opakovania" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CalendarMonthIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={new Date(schedule.date_from).toLocaleDateString()} secondary="Od" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CalendarMonthIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={new Date(schedule.date_to).toLocaleDateString()} secondary="Do" />
                                        </ListItem>
                                    </>
                                }
                                {schedule.repeat_type === "1" &&
                                    <>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ReplayIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Každý deň" secondary="Typ opakovania" />
                                        </ListItem>
                                    </>
                                }
                                {schedule.repeat_type === "2" &&
                                    <>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ReplayIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Každý týždeň" secondary="Typ opakovania" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CalendarMonthIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={repeatAtDays.join(', ')} secondary="V dni" />
                                        </ListItem>
                                    </>
                                }
                                {schedule.repeat_type === "3" &&
                                    <>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ReplayIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Každý rok" secondary="Typ opakovania" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CalendarMonthIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={new Date(schedule.repeat_at.date).toLocaleDateString().slice(0, -4)} secondary="V deň" />
                                        </ListItem>
                                    </>
                                }

                                <Divider />
                                {schedule.Program === null &&
                                    <>
                                        <ListItem>
                                            <ListItemText primary="Jednoduché zvonenie" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={schedule.start_time} secondary="Čas začatia zvonenia" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={schedule.duration} secondary="Trvanie zvonenia" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={bells.join(', ')} secondary="Zvony" />
                                        </ListItem>

                                    </>
                                }

                                {schedule.Program !== null &&
                                    <>
                                        <ListItem>
                                            <ListItemText primary={schedule.Program.name} secondary="Názov programu" />
                                        </ListItem>
                                    </>
                                }
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.props.onDelete} color='error'>Odstrániť</Button>
                            <div style={{ flex: '1 0 0' }} />
                            <Button onClick={this.props.onEdit}>Upraviť</Button>
                            <Button onClick={this.props.onClose}>Zavrieť</Button>
                        </DialogActions>
                    </>
                }
            </Dialog>

        );
    }
}

export default ScheduleDialog;

