import React from 'react';
import socketio from "socket.io-client";


const socket = socketio.connect();
export const AppContext = React.createContext();

export const AppContextProvider = (props) => {
    let usr = {
        "id": 1,
        "name": "Peter Petrík",
        "email": "peter.petrik.fefe@gmail.com",
        "city": "novadedinka",
        "role": "administrator",
        "belfries": [
            {
                "id": "862205052611540",
                "name": "Nová Ves",
                "city": "1",
                "Bells": [
                    {
                        "id": 1,
                        "number": 0,
                        "name": "Malý zvon",
                        "size": "small",
                        "belfry": "862205052611540"
                    },
                    {
                        "id": 2,
                        "number": 1,
                        "name": "Veľký zvon",
                        "size": "big",
                        "belfry": "862205052611540"
                    }
                ]
            },
            {
                "id": "862205052611565",
                "name": "Šáp",
                "city": "1",
                "Bells": [
                    {
                        "id": 3,
                        "number": 0,
                        "name": "Malý zvon",
                        "size": "small",
                        "belfry": "862205052611565"
                    },
                    {
                        "id": 4,
                        "number": 1,
                        "name": "Veľký zvon",
                        "size": "big",
                        "belfry": "862205052611565"
                    }
                ]
            }
        ]
    };

    usr = null;

    const [socketUserLoading, setSocketUserLoading] = React.useState(true);
    const [user, setUser] = React.useState(usr);
    const [currentBelfryID, setCurrentBelfryID] = React.useState(null);
    const [bellsStates, setBellsStates] = React.useState({});

    window.socket = socket;

    socket.on("connect", () => {
        if (!user) {
            socket.emit("getUser", (data) => {
                if (data) {
                    setUser(data);

                    if (data.belfries.length > 0) {
                        setCurrentBelfryID(data.belfries[0].id);
                    }
                }
                setSocketUserLoading(false);
            });
        }

        socket.emit("getLastBellsStates", (data) => {
            setBellsStates(data);
        });
    });

    socket.on("disconnect", () => {
        setUser(null);
        setSocketUserLoading(false);
    });


    socket.on("bell_state", (data) => {
        let newBellsStates = { ...bellsStates };
        if (newBellsStates[data.belfryID] === undefined) {
            newBellsStates[data.belfryID] = {};
        }
        newBellsStates[data.belfryID][data.bellNumber] = data.state;
        setBellsStates(newBellsStates);
    });

    const bellState = {
        UNKNOWN: "-2",
        BLOCKED: "-1",
        STOPPED: "0",
        STOPPING: "1",
        STARTING: "2",
        RINGING: "3",
    }

    const days = {
        0: "Nedeľa",
        1: "Pondelok",
        2: "Utorok",
        3: "Streda",
        4: "Štvrtok",
        5: "Piatok",
        6: "Sobota",
    }



    let providerValues = {
        socket: socket,
        user: user,
        setUser: setUser,
        socketUserLoading: socketUserLoading,
        setSocketUserLoading: setSocketUserLoading,
        currentBelfryID: currentBelfryID,
        setCurrentBelfryID: setCurrentBelfryID,
        bellsStates: bellsStates,
        setBellsStates: setBellsStates,
        bellState: bellState,
        days: days,
    };


    return (
        <AppContext.Provider value={providerValues}>
            {props.children}
        </AppContext.Provider>
    );
};


